.thank {
    height: calc(100vh - 91px);
    background: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.thank__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: translateY(-10%);
}

.thank__title {
    color: #000;
    margin-bottom: 38px;
}

.thank__arrow_link {
    border-radius: 50%;
    display: block;
    background-image: url(../../img/arrow_link.svg);
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    width: 35px;
    height: 35px;
    position: relative;
    transition: background-position .4s ease;
    transform: rotate(180deg);
}

.thank__arrow_link::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 35px;
    height: 35px;
    background-image: url(../../img/arrow_link.svg);
    background-repeat: no-repeat;
    background-position: center;
    transition: left 0.4s ease;
}

.thank__link:hover .thank__arrow_link {
    background-position: 45px;
}

.thank__link:hover .thank__arrow_link::before {
    left: 0;
}

.thank__link {
    transform: translateX(-22px);
    margin-top: 133px;
    display: flex;
    gap: 20px;
    align-items: center;
}    

@media screen and (max-width: 1024px) {
    .thank__link {
        position: inherit;
        top: inherit;
        right: inherit;

    }
}

@media screen and (max-width:860px) {
    .thank__container {
        text-align: center;
    }
}
