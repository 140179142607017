.amenities {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.amenities__title_container {
    max-width: 1920px;
    width: 100%;
    padding: 114px 145px 80px 145px;
}

.amenities__icon__container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.amenities__icon__picture {
    width: 176px;
    height: 180px;
}

.amenities__subtitle {
    position: absolute;
    bottom: 129px;
    right: 25%;
    transform: translateX(50%);
    text-align: center;
    width: auto;
}

.amenities__subtitle_link {
    font-family: Apoc;
    font-size: 20px;
    font-weight: 900;
    line-height: 28px;
    text-align: center;
    text-decoration: underline;
}

@media screen and (max-width: 1575px) {
    .amenities__title_container {
        padding: 60px 100px;
    }
}

@media screen and (max-width: 1175px) {
    .amenities__title_container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding: 52px 20px 30px 20px;
    }
}

@media screen and (max-width: 860px) {
    .amenities__subtitle {
        bottom: 88px;
        right: 50%;
        -webkit-transform: translateX(50%);
            -ms-transform: translateX(50%);
                transform: translateX(50%);
        text-align: center;
        width: 250px;
    }
}