.offices {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    padding-bottom: 258px;
}

.offices__container {
    width: 100%;
    padding: 174px 140px 60px 140px;
    max-width: 1920px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.offices__title {
    color: #000;
    text-align: left;
    font-weight: 800;
    line-height: 96px;
}

.offices__title_regular {
    font-weight: 400;
}

.offices__intro_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 140px;
    margin-top: 73px;
}

.offices__intro_block-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 49.5%;
}

.offices__icons_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.offices__icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 22px;
    max-width: 382px;
}

.office__intro_link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 20px;
}

.office__intro_link:hover .arrow__link {
    background-position: 45px;
}

.office__intro_link:hover .arrow__link::before {
    left: 0;
}

.offices__plan {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 50.5%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 78px;
}

.offices__plan_image {
    height: 100%;
    max-height: 780px;
}

.offices__plan_link {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    border-bottom: #000 1px solid;
}

.offices__list {
    margin-bottom: 26px;
}

@media screen and (max-width: 1575px) {
    .offices {
        padding-bottom: 200px;
    }

    .offices__container {
        padding: 80px 100px 0px 100px;
    }

    .offices__title {
        font-size: 64px;
        line-height: 67.2px;
    }

    .offices__intro_container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-top: 47px;
    }

    .offices__intro_block-left {
        width: 100%;
    }

    .offices__icons_container {
        -ms-flex-pack: distribute;
            justify-content: space-around;
    }

    .office__intro_link {
        margin-top: 60px;
        margin-bottom: 60px;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }

    .offices__plan {
        width: 75%;
        height: 100%;
        -ms-flex-item-align: center;
            -ms-grid-row-align: center;
            align-self: center;
    }

    .offices__plan_image {
        width: 100%;
        max-height: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .offices__container {
        padding: 60px 40px 0px 40px;
    }
    
    .offices__title {
        font-size: 49px;
        line-height: 48.02px;
        text-align: center;
    }
}

@media screen and (max-width: 860px) {
    .offices__plan {
        gap: 24px;
        width: 100%;
    }

    .offices {
        padding-bottom: 166px;
    }

    .offices__intro_container {
        margin-bottom: 60px;
    }

    .offices__icons_container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        gap: 22px;
    }

    .offices__icons:last-child {
        margin-bottom: 117px;
    }

    .office__intro_link {
        display: none;
    }
}

@media screen and (max-width: 850px) {
    .offices__container {
        padding: 60px 20px 0px 20px;
    }

    .offices__title {
        font-size: 49px;
        text-align: center;
        line-height: 48px;
    }
}
