.header__ident {
    height: 89px;
}

.header {
    margin: 0 auto;
    color: rgba(37, 37, 37);
    text-transform: uppercase;
    min-width: 320px;
    width: 100%;
    height: 90px;
    position: fixed;
    top: 0;
    background: #FFF;
    background-color: rgba(255, 255, 255);
    border-bottom: 1px solid rgba(37, 37, 37);
    z-index: 3000;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    text-align: center;
}

.header--visible {
    opacity: 1 !important;
    -webkit-transform: translateY(0) !important;
        -ms-transform: translateY(0) !important;
            transform: translateY(0) !important;
}

.header__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 1920px;
    margin: 0 auto;
    height: 100%;
    position: relative;
    padding: 0 140px;
}

.header__logo_container {
    margin-right: 30px;
}

.header__logo {
    height: 53px;
    width: 52px;
    cursor: pointer;
    z-index: 1000;
}

.header__animated {
    opacity: 0;
    -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
            transform: translateY(-10px);
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.6s ease-out;
    transition: opacity 0.2s ease-out, -webkit-transform 0.6s ease-out;
    -o-transition: opacity 0.2s ease-out, transform 0.6s ease-out;
    transition: opacity 0.2s ease-out, transform 0.6s ease-out;
    transition: opacity 0.2s ease-out, transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
}

.header__nav-menu {
    height: 100%;
    padding-left: 116px;
}

.header__menu_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style-type: none;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-column-gap: 58px;
       -moz-column-gap: 58px;
            column-gap: 58px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    background-color: #FFF;
    visibility: visible;
}

.header__menu_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.header__link_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.header__menu_link {
    cursor: pointer;
    position: relative;
    text-decoration: none;
    color: #252525;
}

.animated_underline::after {
    cursor: pointer;
    position: absolute;
    content: '';
    bottom: -4px;
    display: block;
    width: 0;
    height: 2px;
    background: rgba(37, 37, 37);
    -webkit-transition: .3s ease-out;
    -o-transition: .3s ease-out;
    transition: .3s ease-out;
    left: 0;
}

.animated_underline:hover:after {
    width: 100%;
}

.header__slider-menu_active {
    -webkit-transform: translateY(0) !important;
        -ms-transform: translateY(0) !important;
            transform: translateY(0) !important; 
    -webkit-transition:  .4s; 
    -o-transition:  .4s; 
    transition:  .4s;
}

.header__slider-menu_inactive {
    -webkit-transition:  .4s;
    -o-transition:  .4s;
    transition:  .4s;
}

.header__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 56px;
       -moz-column-gap: 56px;
            column-gap: 56px;
    height: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 100;
    margin-left: 30px;
}

a:visited {
    color: #252525;
}

.header__menu_open {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: translateY 0.2 ease-in-out;
    -o-transition: translateY 0.2 ease-in-out;
    transition: translateY 0.2 ease-in-out;
}

.header__link-auth {
    cursor: pointer;
    text-decoration: none;
}

.header__auth_container {
    position: relative;
    opacity: 0;
    -webkit-transition-delay: .5s;
         -o-transition-delay: .5s;
            transition-delay: .5s;
}

.header__lang_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style-type: none;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    color: rgba(37, 37, 37, .45);
    padding-right: 13px;
}

.header__selected {
    color: rgba(37, 37, 37) !important;
    cursor: default;
    pointer-events: none;
}

.header__lang_item {
    position: relative;
    margin: 0 8px;
    opacity: 0;
    -webkit-transition-delay: .6s;
         -o-transition-delay: .6s;
            transition-delay: .6s;
}

.header__lang_item::after {
    position: absolute;
    content: '';
    top: 0;
    display: block;
    width: 1.5px;
    height: 24px;
    background: rgba(37, 37, 37, .45);
    right: -10px;
}

.header__lang_item:last-child::after {
    width: 0;
}

.header__lang_link {
    text-decoration: none;
    color: rgba(37, 37, 37, 0.45);
}

#burger {
    display: none;
    opacity: 0;
    -webkit-transition-delay: .3s;
         -o-transition-delay: .3s;
            transition-delay: .3s;
}

#burger:checked + [for="burger"]:before {
    -webkit-transform: translateY(14px) rotateZ(45deg);
        -ms-transform: translateY(14px) rotate(45deg);
            transform: translateY(14px) rotateZ(45deg);
}

#burger:checked + [for="burger"]:after {
    -webkit-filter: initial;
            filter: initial;
    -webkit-transform: translateY(12px) rotateZ(-45deg);
        -ms-transform: translateY(12px) rotate(-45deg);
            transform: translateY(12px) rotateZ(-45deg);
}

.header__burger_label {
    z-index: 1000;
    border-radius: 1px;
    color: #000000;
    cursor: pointer;
    height: 30px;
    margin: auto;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 30px;
    display: none;
}

.header__burger_label:before,
.header__burger_label:after {
    border-radius: inherit;
    background: currentColor;
    content: "";
    display: block;
    height: 1px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    border-width: 1px;
    border-style: solid;
}

.header__burger_label:after {
    -webkit-filter: drop-shadow(0 -14px currentColor);
            filter: drop-shadow(0 -14px currentColor);
    -webkit-transform: translateY(26px);
        -ms-transform: translateY(26px);
            transform: translateY(26px);
}

@media screen and (max-width: 1575px) {
    .header__container {
        padding: 0 100px;
    }

    .header__logo {
        height: 42px;
        width: 41px;
    }

    .header__nav-menu {
        padding-left: 0px;
    }

    .header__menu_list {
        -webkit-column-gap: 25px;
           -moz-column-gap: 25px;
                column-gap: 25px;
    }

    .header__menu_link {
        font-size: 16px;
        line-height: 26px;
    }

    .header__links {
        -webkit-column-gap: 25px;
           -moz-column-gap: 25px;
                column-gap: 25px;
        font-size: 16px;
    }

    .header__lang_list {
        padding-right: 0;
    }
}

@media screen and (max-width: 1024px) {
    .header__ident {
        height: 59px;
    }

    .header {
        height: 60px;
    }

    .header__container {
        padding: 0 40px;
    }

    .header__lang_list {
        padding-right: 0;
    }
}

@media screen and (max-width: 900px) {
    .header__menu_link {
        font-size: 14px;
        line-height: 18px;
    }
}

@media screen and (max-width: 850px) {
    .header__ident {
        height: 69px;
    }

    .header {
        height: 71px;
        padding-right: 21px;
    }

    .header__container {
        padding: 0 25px 0 21px;
    }

    .header__menu_list {
        position: fixed;
        -webkit-transform: translateY(calc(-100vh - 268px));
            -ms-transform: translateY(calc(-100vh - 268px));
                transform: translateY(calc(-100vh - 268px)); 
        top: 71px;
        left: 0px;
        background: #FFF;
        width: 100%;
        display: block;
        text-align: left;
        z-index: 100;
        min-height: 500px;
    }

    .header__menu_item {
        padding-left: 20px;
        padding-top: 35px;
        -webkit-box-pack: left;
            -ms-flex-pack: left;
                justify-content: left;
    }

    .header__menu_link {
        font-size: 28px;
        line-height: 32px;
    }

    .header__links {
        min-width: 290px;
        position: fixed;
        padding: 0 20px;
        bottom: 60px;
        left: 0px;
        background-color: #FFF;
        -webkit-transform: translateY(calc(-100vh - 57px));
            -ms-transform: translateY(calc(-100vh - 57px));
                transform: translateY(calc(-100vh - 57px));
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        width: calc(100vw);
        height: 57px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        font-size: 28px;
        -webkit-column-gap: 0;
           -moz-column-gap: 0;
                column-gap: 0;
        margin-left: 0;
    }

    .header__burger_label {
        display: block;
        position: absolute;
        top: 22px;
        right: 0px;
    }
}

@media screen and (max-width: 320px) {
    .header__menu_list {
        visibility: hidden;
    }

    .header__links {
        visibility: hidden;
    }
}