.parallax {
    overflow: hidden;
    height: 600px;
    width: 100%;
    position: relative;
}

.paral_img {
    position: absolute;
}

@media screen and (max-width:1400px) {
    .parallax {
        height: 450px;
    }
}   

@media screen and (max-width:850px) {
    .parallax {
        height: 328px;
    }
}