.contact {
    background-color: #F3F3F3;
    width: 100%;
    min-height: 1250px;
    height: calc(105vh - 91px);
}

.contact__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1920px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0 auto;
    height: 100%;
}

.contact__image_container {
    position: relative;
    width: 50%;
    overflow: hidden;
}

.contact__image {
    height: 105%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translateX(50%) translateY(-50%);
        -ms-transform: translateX(50%) translateY(-50%);
            transform: translateX(50%) translateY(-50%);
}

.contact__title {
    max-width: 659px;
    margin-bottom: 38px;
    white-space: nowrap;
}

.contact__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 145px 140px 76px 140px;
    width: 50%;
}

.contact__subtitle {
    margin-bottom: 43px;
    max-width: 659px;
}

.contact__input {
    height: 42px;
    border: 1px solid #000000;
    color: #878787;
    font-family: Inter;
    font-size: 20px;
    font-weight: 300;
    line-height: 24.2px;
    padding: 7.5px 13.5px;
    outline: none;
    width: 100%;
    border-radius: 0;
}

.contact__name_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 22px;
    width: 50%;
    max-width: 309px;
}

.contact__label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    display: block;
    margin: 0 0 0 0;
}

.contact__about_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 659px;
    gap: 20px;
    margin-bottom: 33px;
}

.contact__email_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 22px;
    width: 50%;
    max-width: 309px;
}

.contact__input_email {
    width: 100%;
}

.contact__additional_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 27px;
    max-width: 659px;
    margin-bottom: 51px;
}

.contact__input_additional {
    width: 100%;
    height: 309px;
    padding: 12.46px 9.4px 12.46px 18.81px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #757575;
    vertical-align: top;
    resize: none;
}

.contact__submit {
    width: 113px !important;
    /* height: 47px; */
}

/* Media Queries */

@media screen and (max-width: 1575px) {
    .contact__form {
        padding: 145px 100px 76px 100px;
    }
}

@media screen and (max-width: 1280px) {


    .contact__input {
        font-size: 18px;
        font-weight: 300;
        line-height: 21.78px;
        padding: 7px 10px;
        height: 31px;
    }

    .contact__name_container, .contact__email_container {
        width: 100%;
        max-width: none;
        gap: 11px;
    }

    .contact__about_container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        width: 100%;
        max-width: none;
        gap: 15px;
        margin-bottom: 22px;
    }

    .contact__additional_container {
        width: 100%;
        max-width: none;
        gap: 11px;
        margin-bottom: 65px;
    }

    .contact__input_additional {
        height: 309px;
    }
}

@media screen and (max-width: 1024px) {
    .contact {
        height: 100%;
        min-height: unset;
    }

    .contact__container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .contact__title {
        text-align: center;
        max-width: none;
        margin-bottom: 48px;
        letter-spacing: 0.1px;
    }

    .contact__subtitle {
        max-width: none;
        text-align: center;
        margin-bottom: 26px;
        line-height: 28px;
        padding: 0 10px;
    }

    .contact__image_container {
        display: none;
    }

    .contact__form {
        width: 100%;
    }

    .contact__input_additional {
        height: 309px;
    }
}

@media screen and (max-width: 860px) {
    .contact__form {
        padding: 88px 50px 79px 50px;
    }

    .contact__input_additional {
        height: 266px;
    }

    .contact__submit {
        margin: 0 auto;
    }
}

