.about {
    height: 100%;
    background-color: #FFF;
    width: 100%;
}

.about__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1920px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0 auto;
    height: 100%;
    position: relative;
}

.about__info {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 174px 80px 150px 142px;
}

.about__image_container {
    position: absolute;
    overflow: hidden;
    width: 50%;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
}

.about__image {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate( -50%, -50%);
        -ms-transform: translate( -50%, -50%);
            transform: translate( -50%, -50%);
    height: 100%;
    width: auto;

}

.about__title {
    color: #000;
    font-weight: 800;
    margin-bottom: 117px;
    white-space: nowrap;
}

.about__title_regular {
    font-family: Apoc;
    font-size: 96px;
    font-weight: 400;
    line-height: 94.08px;
}

.about__aboutus_text {
    font-family: Inter;
    font-size: 26px;
    font-weight: 400;
    line-height: 36.92px;
    text-align: left;
}

@media screen and (max-width: 1575px) {
    .about__info {
        padding: 80px 80px 150px 100px;
    }

    .about__title {
        font-size: 64px;
        line-height: 68px;
    }

    .about__title_regular {
        font-size: 64px;
        line-height: 68px;
    }

    .about__aboutus_text {
        font-size: 20px;
        line-height: 30px;
    }
}


@media screen and (max-width: 1024px) {
    .about {
        height: auto;
    }

    .about__container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }

    .about__info {
        width: 100%;
        padding: 60px 40px 150px 40px;
    }

    .about__image_container {
        position: static;
        width: calc(100% + 80px);
        -webkit-transform: translateX(-40px);
            -ms-transform: translateX(-40px);
                transform: translateX(-40px);
        min-height: 588px;
        margin-bottom: 57px;
    }

    .about__image {
        width: 100%;
        height: auto;
        -webkit-transform: translate( -50%, -70%);
            -ms-transform: translate( -50%, -70%);
                transform: translate( -50%, -70%);
    }

    .about__title {
        font-size: 49px;
        line-height: 48.02px;
        text-align: center;
        margin-bottom: 42px;
    }

    .about__title_regular {
        font-size: 49px;
        line-height: 48.02px;
        text-align: center;
    }
}

@media screen and (max-width: 860px) {
    .about__info {
        padding: 74px 20px 100px 20px;
    }

    .about__image {
        -webkit-transform: translate( -50%, -50%);
            -ms-transform: translate( -50%, -50%);
                transform: translate( -50%, -50%);
        width: 120%;
    }
}