.amenities-block__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1920px;
    margin: 60px auto;
    padding: 0 140px;
    position: relative;
}

.amenities-block__icon {
    width: 248px;
    height: 248px;
    position: absolute;
    left: 140px;
    top: -20px;
}

.amenities-block__text {
    max-width: 800px;
    margin-left: 248px;
    padding-left: 38px;
}

.amenities-block__text_title {
    padding-bottom: 50px;
}

.amenities-block__text_subtitle {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
}

.amenities-block__link {
    text-decoration: underline;
    font-family: Apoc;
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
    white-space: nowrap;
}

@media screen and (max-width: 1575px) {
    .amenities-block__info {
        padding: 0 100px;
    }

    .amenities-block__icon {
        left: 100px;
    }
}

@media screen and (max-width: 1024px) {
    .amenities-block__info {
        padding: 0 40px;
    }

    .amenities-block__icon {
        left: 40px;
    }
}

@media screen and (max-width: 860px) {
    .amenities-block__icon {
        width: 100px;
        height: 100px;
        left: 30px;
        top: 10px;
    }

    .amenities-block__text {
        margin-left: 123px;
        padding-left: 20px;
    }

    .amenities-block__text_title {
        padding-bottom: 25px;
    }

    .amenities-block__text_subtitle {
        font-size: 14px;
        line-height: 16.94px;
    }

    .amenities-block__link {
        font-size: 14px;
        line-height: 17.5px;
    }
}

@media screen and (max-width: 850px) {
    .amenities-block__info {
        padding: 0 20px;
        margin: 40px auto;
    }
}