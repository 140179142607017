.services {
    width: 100%;    
    display: -webkit-box;    
    display: -ms-flexbox;    
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.services__container {
    width: 100%;
    padding: 174px 140px 18px 140px;
    max-width: 1920px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.services__title {
    color: #000;
    margin-bottom: 110px;
    font-weight: 800;
}

.services__subtitle {
    max-width: 1227px;
    font-family: Inter;
    font-size: 26px;
    font-weight: 400;
    line-height: 36.92px;
    margin-bottom: 180px;
}

.services__amenites {
    padding: 120px 140px 0 140px;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
}

.services__amenites_title {
    margin-bottom: 70px;
    padding-left: 7px;
    line-height: 64px;
}

.services__amenites_subtitle {
    font-family: Inter;
    font-size: 26px;
    font-weight: 400;
    line-height: 36.92px;
    text-align: left;
    max-width: 1310px;
    margin-bottom: 50px;
}

.services__amenites_subtitle-hidden {
    display: none;
}

@media screen and (max-width: 1575px) {
    .services__container {
        padding: 80px 100px 18px 100px;
    }

    .services__title {
        font-size: 64px;
        line-height: 67.2px;
    }

    .services__amenites {
        padding: 80px 100px 0 100px;
    }
}

@media screen and (max-width: 1024px) {
    .services__container {
        padding: 60px 40px 18px 40px;
    }

    .services__title {
        font-size: 49px;
        line-height: 48.02px;
        text-align: center;
        margin-bottom: 60px;
    }

    .services__amenites {
        padding: 60px 40px 0 40px;
    }
}

@media screen and (max-width: 860px) {
    .services__container {
        padding: 74px 20px 0px 20px;
    }

    .services__subtitle {
        margin-bottom: 70px;
        font-size: 20px;
        line-height: 28.4px;
        text-align: left;
    }

    .services__amenites {
        padding: 30px 20px 20px 20px;
    }

    .services__amenites_title {
        margin-bottom: 0;
        line-height: 36px;
    }

    .services__amenites_subtitle {
        font-size: 20px;
        font-weight: 400;
        line-height: 28.4px;
        margin-bottom: 0;
        margin-top: 60px;
    }

    .services__amenites_subtitle-hidden {
        display: block;
    }
}