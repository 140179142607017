@font-face {
    src: url(./fonts/Inter-Regular.woff) format("woff"),
         url(./fonts/Inter-Regular.woff2) format("woff2");
    font-family: "Inter";
    font-weight: 400;
}
    
@font-face {
    src: url(./fonts/Inter-Medium.woff) format("woff"),
         url(./fonts/Inter-Medium.woff2) format("woff2");
    font-family: "Inter";
    font-weight: 500;
}
  
@font-face {
    src: url(./fonts/Inter-Bold.woff2) format("woff2");
    font-family: "Inter";
    font-weight: 700;
}
    
@font-face {
    src: url(./fonts/Inter-Black.woff) format("woff"),
         url(./fonts/Inter-Black.woff2) format("woff2");
    font-family: "Inter";
    font-weight: 900;
}

@font-face {
    src: url(./fonts/Apoc-Revelations-Trial-Regular.otf) format("opentype"),
         url(./fonts/Apoc-Revelations-Trial-Regular.woff) format("woff"),
         url(./fonts/Apoc-Revelations-Trial-Regular.woff2) format("woff2");
    font-family: "Apoc";
    font-weight: 400;
}
    
@font-face {
    src: url(./fonts/Apoc-Revelations-Trial-UltraBold.otf) format("opentype"),
         url(./fonts/Apoc-Revelations-Trial-UltraBold.woff) format("woff"),
         url(./fonts/Apoc-Revelations-Trial-UltraBold.woff2) format("woff2"); 
    font-family: "Apoc";
    font-weight: 800;
}

@font-face {
    src: url(./fonts/Apoc-Revelations-Trial-Dark.otf) format("opentype"),
         url(./fonts/Apoc-Revelations-Trial-Dark.woff) format("woff"),
         url(./fonts/Apoc-Revelations-Trial-Dark.woff2) format("woff2");
    font-family: "Apoc";
    font-weight: 900;
}