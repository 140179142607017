.office__item {
    width: 100%;
    max-height: 784px;
    border: #AABDFF 4px solid;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    padding: 51px;
    margin-bottom: 26px;
}

.office__images_container {
    position: relative;
    height: auto;
    width: 54.64%;
    overflow: hidden;
}

.office__item_image {
    position: absolute;
    cursor: pointer;
    opacity: 1;
    -webkit-transition: opacity .3s ease-in;
    -o-transition: opacity .3s ease-in;
    transition: opacity .3s ease-in;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
}

.office__item_image-hover {
    opacity: 0;
}

.office__item_image-plan {
    cursor: pointer;
    left: 0;
    width: 100%;
}

.office__item_info {
    width: 40%;
    max-width: 602px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    gap: 27px;
}

.office__item_title {
    margin-left: 3px;
}

.office__item_subtitle {
    text-align: left;
    line-height: 25px;
    font-family: Inter;
}

.office__item_param-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 38px;
    padding-left: 20px;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 76px;
}
.office__item_param {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
}

.office__param_title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    left: 116px;
}

.office__param_value {
    font-family: Inter;
    font-size: 32px;
    font-weight: 400;
    line-height: 45.44px;
    margin-right: 4px;
    white-space: nowrap;
}

.office_param_span {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
}

.office__param_icon {
    margin-right: 75px;
}
.office__item_submit {
    position: absolute;
    width: 265px !important;
    margin-top: 46px;
    bottom: 0;
    left: -5px;
}

@media screen and (max-width: 1575px) {
    .office__item {
        padding: 30px;
    }

    .office__item:last-child {
        margin-bottom: 0px;
    }

    .office__item_info {
        gap: 10px;
    }

    .office__item_title {
        font-size: 48px;
        line-height: 50px;
    }

    .office__item_param-list {
        margin-top: 0;
        margin-bottom: 50px;
        gap: 10px;
        padding-left: 0;
    }
    .office__param_title {
        left: 66px;
        position: static;
    }
}

@media screen and (max-width: 1280px) {
    .office__item_subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        letter-spacing: -0.2px;
    }

    .office__param_value {
        font-size: 24px;
        line-height: 34.08px;
    }

    .office_param_span {
        font-size: 14px;
        line-height: 16.94px;
    }

    .office__param_icon {
        width: 26px;
        height: 26px;
    }
}

@media screen and (max-width: 1080px) {
    .office__item_title {
        font-size: 36px;
        font-weight: 800;
        line-height: 36.44px;
        margin-left: 0;
        letter-spacing: 0;
    }
}

@media screen and (max-width: 860px) {
    .office__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        max-height: none;
        padding: 23px;
        margin-bottom: 60px;
        padding-bottom: 38.5px;
    }

    .office__images_container {
        width: 100%;
    }

    .office__item_info {
        width: 100%;
        position: static;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-top: 25px;
        gap: 0;
    }

    .office__item_subtitle {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .office__item_param-list {
        gap: 20px;
        margin-bottom: 33px;
    }
    .office__param_icon {
        width: 38px;
        height: 38px;
        margin-right: 23px;
    }
    .office__item_submit {
        position: static;
        width:100% !important;
        max-width: 265px;
        margin-top: 0;
    }
}