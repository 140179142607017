.icons {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    max-width: 1920px;
    padding: 100px 124px;
    /* -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; */
    row-gap: 100px;
    background-color: #FFF;
}

.icons__additional {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    max-width: 1920px;
    padding: 0 20px 0 44px;
    /* -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; */
    gap: 130px;
}

.icons__amenities {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 26px;
    padding: 8px 10px 208px 10px;
}

@media screen and (max-width: 1575px) {
    .icons {
        -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 100px 100px;
    }
}

@media screen and (max-width: 1175px) {
    .icons__additional {
        gap: 60px;
    }
}

@media screen and (max-width: 1024px) {
    .icons {
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 860px) {
    .icons {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        padding: 60px 20px;
        row-gap: 28px;
    }

    .icons__additional {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 0px 20px;
        gap: 60px;
    }

    .icons__amenities {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        /* -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; */
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding: 0 20px 163px 20px;
        gap: 60px;
    }
}

@media screen and (max-width: 480px) {
    .icons {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        padding: 60px 20px;
    }
}