.contactus__subtitle {
    text-align: center;
    margin-top: 176px;
}

.contactus__subtitle_link {
    text-decoration: underline;
    font-family: Apoc;
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
}

@media screen and (max-width: 860px) {
    .contactus__subtitle {
        max-width: 340px;
        margin: 130px auto 0 auto;
        line-height: 25px;
    }
}