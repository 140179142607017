.carousel {
    overflow: hidden;
    width: 100%;
    padding: 12px 0;
    max-height: 100%;
    min-height: 100%;
}

.swiper-slide img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    image-rendering: crisp-edges;
    padding: 8px;
    border: none !important;
}

@media screen and (max-width: 859px) {
    .carousel {
        padding: 12px 10px;
    }
}
  