.offices__icon {
    display: flex;
    align-items: center;
    max-width: 380px;
    gap: 40px;
}

.offices__icon_title {
    text-align: left;
    max-width: 238px;
}    

@media screen and (max-width:1575px) {
    .offices__icon {
        gap: 23px;
        margin-left: 14px;
    }
}