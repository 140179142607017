.cards {
    width: 100%;
    padding-bottom: 42px;
}

.cards_title {
    margin-bottom: 110px;
}

.card__icon {
    width: 240px;
    height: 240px;
}

.card__icon_title {
    text-align: center;
}

.cards__container {
    width: 100%;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    row-gap: 45px;
    -webkit-column-gap: 41px;
       -moz-column-gap: 41px;
            column-gap: 41px;
}

.card {
    -webkit-perspective: 1500px;
            perspective: 1500px;
    position: relative;
    height: 374px;
    cursor: pointer;
}

.card__side {
    border: #5f5ff6 3px solid;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all .6s ease;
    -o-transition: all .6s ease;
    transition: all .6s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    padding: 29px 20px 0 20px;
}

.card__side--back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    border: 0;
    padding: 42px 29px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
}

.card__side-back_text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 13;
    overflow: hidden;
}

/* .card:hover .card__side--front, */
.card.is-flipped .card__side--front {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
}

/* .card:hover .card__side--back, */
.card.is-flipped .card__side--back {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
}

@media screen and (max-width: 860px) {
    .cards {
        padding-bottom: 40px;
    }

    .cards_title {
        margin-bottom: 24px;
    }

    .card__icon {
        width: 160px;
        height: 160px;
        padding-bottom: 8px;
    }

    .cards__container {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        row-gap: 35px;
    }

    .card {
        height: 252px;
    }

    .card__side {
        padding: 20px 20px 0 20px;
    }

    .card__side--back {
        font-size: 14px;
        line-height: 16px;
        padding: 20px;
    }

    .card__side-back_text {
        -webkit-line-clamp: 9;
    }
}