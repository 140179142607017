.footer {
    color: rgba(37, 37, 37);
    min-width: 320px;
    width: 100%;
    height: 506px;
    border-top: 1px solid rgb(0, 0, 0);
}

.footer__container {
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 1920px;
    min-width: 320px;
    padding: 92px 140px;
}

.footer__info {
    width: 46.3%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 800px;
}

.footer__block_title {
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    color: #979797;
    margin-bottom: 11px;
}

.footer__block_info {
    min-width: 200px;
}

.footer__item_hidden {}

.footer__block_item {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
}

.footer__block_item-about:nth-child(2) {}

.footer__block_list {
    padding-top: 10px;
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 15px;
}

.footer__block-icon_list {
    padding-top: 0 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 23px !important;
}

.footer__block-icon {
    position: absolute;
    bottom: -6px;
    right: 0;
}

.footer__block_account {}

.footer__block-icons_title {
    margin-bottom: 26px !important;
}

.footer__block-icon_item {
    width: 35px;
    height: 35px;
}

.footer__logo {
    width: 50%;
    max-width: 800px;
    max-height: 120px;
    position: relative;
}

.footer__logo_img {
    width: 100%;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-39%);
        -ms-transform: translateY(-39%);
            transform: translateY(-39%);
}

.footer__copyright {
    display: none;
}

@media screen and (max-width: 1575px) {
    .footer {
        height: 467px;
    }
    .footer__container {
        padding: 92px 100px;
    }
    .footer__info {
        width: 55%;
    }
    .footer__block_title {
        font-size: 16px;
        line-height: 20.2px;
        margin-bottom: 0px;
    }
    .footer__block_info {
        min-width: 140px;
    }
    .footer__block_item {
        font-size: 14px;
        line-height: 20.2px;
    }
    .footer__block-icon_list {
        gap: 21px !important;
    }
    .footer__block-icon_item {
        width: 32px;
        height: 32px;
    }
    .footer__logo {
        width: 40%;
    }
}

@media screen and (max-width: 1024px) {
    .footer {
        height: 380px;
    }
    .footer__container {
        padding: 25px 100px;
    }
    .footer__info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 27px;  
    }
    .footer__item_hidden {
        display: none;
    }
    .footer__block_item {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
    }
    .footer__block_list {
        gap: 8px;
    }
    .footer__block-icon {
        bottom: unset;
        right: 0;
        top: 0;
    }
    .footer__block_account {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media screen and (max-width: 850px) {
    .footer {
        height: 550px;
    }
    .footer__container {
        padding: 25px 20px 35px 19px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        max-width: 500px;
    }
    .footer__info {
        width: 100%;
    }
    .footer__block_title {
        padding-left: 0px;
        margin-bottom: 5px;
    }
    .footer__block_item-about:nth-child(2) {
        -webkit-box-ordinal-group: 6;
            -ms-flex-order: 5;
                order: 5;
    }
    .footer__block-icon {
        right: 20px;
    }
    .footer__block_account {
        right: 21px;
    }
    .footer__logo {
        width: 100%;
        max-width: 350px;
        padding-bottom: 40px;
    }
    .footer__logo_img {
        position: unset;
        -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
                transform: translateY(0);
    }
    .footer__copyright {
        display: inline;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        color: #979797;
        padding-top: 36px;
    }
    .footer__block-icon_item {
        width: 32px;
        height: 32px;
    }
}

@media screen and (max-width: 350px) {
    .footer__block_title {
        font-size: 14px;
    }
    .footer__block_item {
        font-size: 14px;
    }
    .footer__block-icon {
        right: 0px;
    }
    .footer__block_account {
        right: 0px;
    }
}