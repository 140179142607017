.login {
    background-color: #F3F3F3;
    width: 100%;
    height: 100%;
}

.login__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1920px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0 auto;
}

.login__image_container {
    width: 50%;
    overflow: hidden;
    margin-bottom: -4px;
}

.login__image {
    width: 100%;
    margin-top: -77px;
}

.login__title {
    max-width: 659px;
    margin-bottom: 22px;
    white-space: nowrap;
}

.login__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 246px 140px 76px 183px;
    width: 50%;
}

.login__subtitle {
    margin-bottom: 66px;
    max-width: 659px;
}

.login__input {
    height: 49px;
    border: 1px solid #000000;
    color: #878787;
    font-family: Inter;
    font-size: 20px;
    font-weight: 300;
    line-height: 24.2px;
    padding: 7.5px 13.5px;
    outline: none;
    width: 100%;
    margin-bottom: 101px;
    border-radius: 0;
}

.login__label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    display: block;
    margin: 0 0 0 0;
}

.login__email_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 22px;
    width: 100%;
    max-width: 520px;
}

.login__input_email {
    width: 100%;
}

.login__submit {
    width: 151px !important;
}

@media screen and (max-width: 1575px) {
    .login__form {
        padding: 145px 100px 76px 100px;
    }
}

@media screen and (max-width: 1280px) {
    .login__form {
        padding: 100px 100px 50px 100px;
    }
    .login__input {
        font-size: 18px;
        font-weight: 300;
        line-height: 21.78px;
        padding: 7px 10px;
        height: 31px;
        margin-bottom: 114px;
    }
    .login__email_container {
        width: 100%;
        gap: 11px;
    }
}

@media screen and (max-width: 1024px) {
    .login__container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .login__image_container {
        display: none;
    }
    .login__form {
        width: 100%;
    }
}

@media screen and (max-width: 860px) {
    .login__title {
        text-align: center;
        max-width: none;
        margin-bottom: 50px;
    }
    .login__form {
        padding: 90px 50px 165px 50px;
    }
    .login__subtitle {
        max-width: none;
        text-align: center;
        line-height: 28.4px;
        margin-bottom: 36px;
        padding: 0 30px;
        letter-spacing: -.4px;
    }
    .login__email_container {
        margin: 0 auto;
    }
    .login__submit {
        margin: 0 auto;
    }
}