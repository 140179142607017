.additional {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    background-color: #FFF;
}

.additional__container {
    max-width: 1920px;
    width: 100%;
    padding: 114px 145px 160px 145px;
    position: relative;
}

.additional__title_container {
    padding-bottom: 108px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.additional__link {
    position: absolute;
    top: 120px;
    right: 114px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 24px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.arrow__link {
    border-radius: 50%;
    display: block;
    background-image: url(../../../img/arrow_link.svg);
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    width: 35px;
    height: 35px;
    position: relative;
    -webkit-transition: background-position .4s ease;
    -o-transition: background-position .4s ease;
    transition: background-position .4s ease;
}

.arrow__link::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 35px;
    height: 35px;
    background-image: url(../../../img/arrow_link.svg);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: left 0.4s ease;
    -o-transition: left 0.4s ease;
    transition: left 0.4s ease;
}

.additional__link:hover .arrow__link {
    background-position: 45px;
}

.additional__link:hover .arrow__link::before {
    left: 0;
}

@media screen and (max-width: 1575px) {
    .additional__container {
        padding: 80px 100px;
    }
    .additional__link {
        top: 86px;
    }
}

@media screen and (max-width: 1175px) {
    .additional__container {
        padding: 60px 20px 170px 20px;
    }
    .additional__title_container {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        gap: 40px;
        padding-bottom: 50px;
        padding-left: 4px;
    }
    .additional__link {
        bottom: 50px;
        top: inherit;
        right: inherit;
    }
}