.intro {
    height: calc(100vh - 94px);
    width: 100%;
    position: relative;
    min-height: 800px;
}

.intro__slider {
    position: relative;
    width: 100%;
    height: calc(100vh - 90px);
    overflow: hidden;
    min-height: 800px;
}

.intro__image {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top;
       object-position: top;
    opacity: 0;
    -webkit-transition: opacity 1s ease;
    -o-transition: opacity 1s ease;
    transition: opacity 1s ease;
    top: -4px;
}

.intro__slider_blackout {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% - 4px);
    background-color: rgba(45, 45, 45, 0.22);
    z-index: 900;
}

.intro__slider_active {
    opacity: 1;
}

.intro__slider_btn {
    position: absolute;
    z-index: 2000;
    width: 189px;
    height: calc(100vh - 94px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.intro__btn {
    width: fit-content;
    padding: 0 10px;
}

.intro__slider_btn:hover {
    cursor: pointer;
}

.intro__slider_prev {
    left: -20px;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(0, 0, 0, .4)), to(rgba(0, 0, 0, .0001)));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, .4) 0, rgba(0, 0, 0, .0001) 100%);
    background-image: linear-gradient(to right, rgba(0, 0, 0, .4) 0, rgba(0, 0, 0, .0001) 100%);
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
}

.intro__slider_prev:hover {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(0, 0, 0, .6)), to(rgba(0, 0, 0, .0001)));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .0001) 100%);
    background-image: linear-gradient(to right, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .0001) 100%);
}

.intro__slider_next {
    right: -20px;
    background-image: -webkit-gradient(linear, right top, left top, color-stop(0, rgba(0, 0, 0, .4)), to(rgba(0, 0, 0, .0001)));
    background-image: -o-linear-gradient(right, rgba(0, 0, 0, .4) 0, rgba(0, 0, 0, .0001) 100%);
    background-image: linear-gradient(to left, rgba(0, 0, 0, .4) 0, rgba(0, 0, 0, .0001) 100%);
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
}

.intro__slider_next:hover {
    background-image: -webkit-gradient(linear, right top, left top, color-stop(0, rgba(0, 0, 0, .6)), to(rgba(0, 0, 0, .0001)));
    background-image: -o-linear-gradient(right, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .0001) 100%);
    background-image: linear-gradient(to left, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .0001) 100%);
}

.intro__slider_arrow {
    opacity: .6;
    z-index: 900;
    height: 100%;
    width: 100%;
}

.intro__slider_arrow:hover {
    opacity: 1;
}

.intro__slider_arrow-prev {
    background-image: url(../../../img/slides_prev.png);
    background-repeat: no-repeat;
    background-position: center;
}

.intro__slider_arrow-next {
    background-image: url(../../../img/slides_next.png);
    background-repeat: no-repeat;
    background-position: center;
}

.intro__container {
    max-width: 1920px;
    top: 0;
    position: absolute;
    height: calc(100vh - 94px);
    z-index: 1000;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
    padding: 0 140px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 77px;
    min-height: 600px;
}

.intro_title {
    white-space: pre-wrap;
}

.intro_title_mobile {
    display: none;
}

.intro_subtitle {
    font-family: Inter;
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.1px;
}

.intro__btns_container {
    margin-top: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 84px;
    padding-left: 24px;
}

@media screen and (max-width: 1575px) {
    .intro {
        min-height: 500px;
    }

    .intro__slider {
        min-height: 500px;
    }

    .intro__slider_btn {
        width: 100px;
    }

    .intro__container {
        padding: 0 100px;
    }

    .intro_title {
        font-size: 64px;
        line-height: 67.2px;
    }

    .intro__title_span {
        font-size: 64px;
        line-height: 67.2px;
    }
}

@media screen and (max-width: 850px) {
    .intro {
        min-height: 500px;
    }

    .intro__slider {
        min-height: 500px;
    }

    .intro__slider_btn {
        visibility: hidden;
    }

    .intro__container {
        padding: 0 20px;
        gap: 22px;
        min-height: 500px;
    }

    .intro_title {
        display: none
    }
    
    .intro_title_mobile {
        display: inline;
        font-size: 49px;
        text-align: center;
        line-height: 48px;
    }

    .intro__title_span {
        display: none;
    }

    .intro_subtitle {
        font-size: 16px;
        text-align: center;
        line-height: 20px;
        padding-left: 0;
    }

    .intro__btns_container {
        margin-top: 70px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding-left: 0;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        gap: 25px;
    }
}