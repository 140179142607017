@import url(./vendor/normalize.css);
@import url(./vendor/fonts.css);
@import url(./vendor/locomotive-scroll.css);

@import url(./components/App/App.css);

@import url(./components/Header/Header.css);
@import url(./components/Footer/Footer.css);

@import url(./components/Main/Main.css);
@import url(./components/Main/Intro/Intro.css);
@import url(./components/Main/Advants/Advants.css);
@import url(./components/Main/Additional/Additional.css);
@import url(./components/Main/Amenities/Amenities.css);
@import url(./components/Main/Request/Request.css);
@import url(./components/Main/Faq/Faq.css);

@import url(./components/Offices/Offices.css);
@import url(./components/Offices/Icon_offices-page/Icon_office-page.css);
@import url(./components/Offices/OfficeItem/OfficeItem.css);

@import url(./components/Services/Services.css);
@import url(./components/Services/Cards/Cards.css);
@import url(./components/Services/AmenitiesBlock/AmenitiesBlock.css);

@import url(./components/Contact/Contact.css);
@import url(./components/About/About.css);
@import url(./components/Login/Login.css);
@import url(./components/ThankYou/ThankYou.css);

@import url(./components/Parallax/ParallaxComponent.css);
@import url(./components/Carousel/Carousel.css);
@import url(./components/Icons/Icon/Icon.css);
@import url(./components/Icons/Icons.css);
@import url(./components/ContactUsLink/ContactUsLink.css);

@import url(./components/Offices/Offices.css);

*{
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-family: Inter;
}

button, 
a,
div {
    -webkit-tap-highlight-color: transparent;
}

h1 {
    font-family: Apoc;
    font-size: 96px;
    font-weight: 900;
    color: #FFF;
    line-height: 102px;
    margin: 0;
    padding: 0;
    letter-spacing: 0.4px;
}

h2 {
    font-family: Apoc;
    font-size: 64px;
    font-weight: 800;
    color: #000;
    line-height: 67.2px;
    margin: 0;
    padding: 0;
    text-align: left;
    letter-spacing: 0.4px;

}

h3 {
    font-family: Apoc;
    font-size: 36px;
    font-weight: 900;
    color: #000;
    line-height: 37.8px;
    margin: 0;
    padding: 0;
    text-align: left;
}

p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    color: #000;
    line-height: 22px;
    margin: 0;
    padding: 0;
}

.line-break {
    display: block;
    height: 1em;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: #000;
}

.btn {
    border: 1px solid #000;
    width: 194px;
    height: 47px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    text-align: center;
    color: #252525;
    cursor: pointer;
    background-color: #FFF;
    -webkit-transition: background-color .3s ease, color .3s ease;
    -o-transition: background-color .3s ease, color .3s ease;
    transition: background-color .3s ease, color .3s ease;
}

.btn:hover {
    background-color: #000;
    color: #FFF;
}

.flex-column-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 84px;
}

@media screen and (max-width: 1575px) {
    .flex-column-left {
        height: 70px;
    }
}

@media screen and (max-width: 860px) {
    .line-break {
        height: 0; 
    }

    .line-break-mobile {
        display: block;
        height: 2em; 
    }

    .flex-column-left {
        height: 58px;
    }
}

@media screen and (max-width: 850px) {
    h2 {
        font-size: 36px;
        text-align: center;
        line-height: 46.44px;
        letter-spacing: 0.95px;
    }
    
    h3 {
        font-size: 24px;
        line-height: 25.2px;
    }
}