.faq {
    background-color: #FFF;
    width: 100%;
    height: 100%;
}

.faq__container {
    max-width: 1920px;
    padding: 114px 140px 207px 140px;
    margin: 0 auto;
}

.faq__title {
    margin-bottom: 34px;
}

.faq__accord {
    list-style-type: none;
}

.faq__accord_item {          
    border-bottom: 1px solid #000000;
}

.faq__accord_item:last-child {
    border-bottom: none;
}

.faq__accord_header {
    font-family: Inter;
    font-size: 26px;
    font-weight: 400;
    line-height: 37px;
    text-align: left;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 100%;
    min-height: 82px;
    position: relative;
    outline: none;
    padding: 22.5px 100px 22.5px 0;
    color: #000;
}

.faq__accord_goriz-line,
.faq__accord_vert-line {
    content: '';
    position: absolute;
    right: 30px;
    width: 43px;
    height: 3px;
    background-image: url('../../../img/burger-line.svg');
    background-size: contain;
    top: 51%;
    transform: translateY(-50%);
}

.open {
    transform: rotate(0deg) !important;
}

.faq__accord_collapce.open {
    height: auto;
}

.faq__accord_collapce {
    height: 0;
    overflow: hidden;
    transition: height 0.3s;
}

.faq__accord_body {
    max-width: 1080px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    padding: 16px 0 22px 0;
}

.faq__accord_vert-line {
    transform: rotate(-90deg);
    height: 3px;
    top: 50%;
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
}

@media screen and (max-width: 1575px) {
    .faq__container {
        padding: 148px 100px 207px 100px;
    }
}

@media screen and (max-width: 860px) {
    .faq__container {
        padding: 112px 20px 146px 20px;
    }

    .faq__title {
        margin-bottom: 0;
    }

    .faq__accord_header {
        font-size: 20px;
        line-height: 28.4px;
        padding: 21px 100px 21px 0;
        min-height: 82px;
    }

    .faq__accord_vert-line,
    .faq__accord_goriz-line {
        width: 33px;
        right: 5px;
        height: 2px;
        top: 50%;
        margin-top: 0;
    }

    .faq__accord_body {
        font-size: 14px;
        line-height: 16.94px;
        padding: 0 0 20px 0;
    }
}


