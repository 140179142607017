.advants {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    background-color: #FFF;
}

.advants__title_container {
    max-width: 1920px;
    width: 100%;
    padding: 114px 145px 104px 145px;
    position: relative;
}

.advants__link {
    position: absolute;
    top: 120px;
    right: 114px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 24px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.advants__title {
    max-width: 820px;
}

.arrow__link {
    border-radius: 50%;
    display: block;
    background-image: url(../../../img/arrow_link.svg);
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    width: 35px;
    height: 35px;
    position: relative;
    -webkit-transition: background-position .4s ease;
    -o-transition: background-position .4s ease;
    transition: background-position .4s ease;
}

.arrow__link::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 35px;
    height: 35px;
    background-image: url(../../../img/arrow_link.svg);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: left 0.4s ease;
    -o-transition: left 0.4s ease;
    transition: left 0.4s ease;
}

.advants__link:hover .arrow__link {
    background-position: 45px;
}

.advants__link:hover .arrow__link::before {
    left: 0;
}

@media screen and (max-width: 1575px) {
    .advants__title_container {
        padding: 80px 100px;
    }

    .advants__link {
        top: 86px;
    }
}

@media screen and (max-width: 1175px) {
    .advants__title_container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        gap: 40px;
    }

    .advants__link {
        position:static;
        top: unset;
        right: unset;
    }
}

@media screen and (max-width: 850px) {
    .advants__title_container {
        padding: 60px 20px 30px 20px;
    }
}