.request {
    background-color: #F3F3F3;
    width: 100%;
    height: 100%;
    min-height: 700px;
    max-height: 1080px;
}

.request__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1920px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0 auto;
    height: 100%;
}

.request__map {
    width: 49%;
    overflow: hidden;
    margin-bottom: -10px;
}

.request__map_image {
    width: 100%;
    height: auto;
}

.request__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 74px 98px 71px 138px;
    width: 51%;
}

.request__subtitle {
    margin: 6px 0 18px 0;
}

.request__subtitle_span {
    display: none;
}

.request__input {
    height: 42px;
    border: 1px solid #000000;
    color: #878787;
    font-family: Inter;
    font-size: 20px;
    font-weight: 300;
    line-height: 24.2px;
    text-align: left;
    padding: 7.5px 13.5px;
    outline: none;
    border-radius: 0;
}

.request__name_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: stretch;
        -ms-flex-pack: stretch;
            justify-content: stretch;
    gap: 42.7px;
}

.request__first-name_container,
.request__last-name_container {
    width: 49.5%;
}

.request__input_first-name,
.request__input_last-name {
    width: 100%;
}

.request__form_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 84px;
}

.request__label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    display: block;
    margin: 0 0 0 0;
}

.request__label::first-letter {
    text-transform: uppercase;
}

.request__dates_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 24px;
}


.request__dates_container label {
    margin: 0;
}

.request__label_dates {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #000;
}

.request__label_dates-mobile {
    display: none;
}

.request__label_date::first-letter {
    text-transform: lowercase;
}

.p-calendar {
    border: none;
}

.p-component {
    height: 42px;
    border: none;
    color: #878787;
    font-family: Inter;
    font-size: 20px;
    font-weight: 300;
    line-height: 24.2px;
    text-align: left;
    width: 159px;
}

.p-inputtext {
    outline: none;
    border: 1px solid #000000;
    padding-left: 13.5px;
    border-radius: 0;
}

.p-datepicker {
    /* height: 250px; */
    width: 280px;
}

.p-datepicker-header {
    padding: 10px 0;
    background-color: #FFF;
}

.p-datepicker-calendar-container {
    background-color: #FFF;
}

.p-datepicker-calendar-container span{
    height: 25px;
}

.p-datepicker-prev,
.p-datepicker-next {
    height: 30px;
    width: 30px;
}

.p-monthpicker {
    background-color: #FFF;
}

.request__office_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    gap: 32px;
    width: 100%;
}

.request__office_num-blocks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    gap: 12px;
}

.request__input_office {
    width: 201px;
}

.request__input_people {
    width: 49.11px;
    padding-left: 10.45px;
    padding-right: 10.45px;
}

.request__parking_containeer,
.request__people_container {
    width: 50%;
}

.request__input_parking {
    width: 49.11px;
    padding-left: 10.45px;
    padding-right: 10.45px;
}

.request__contact_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 42.84px;
}

.request__email_containeer {
    width: 65%;
}

.request__input_email {
    width: 100%;
}

.request__phone_containeer {
    width: 35%;
}

input[type='number'] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
            appearance: textfield;
}

.request__input_phone {
    width: 100%;
    color: #757575;
}

.request__additional_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0 0 12px 0;
    height: 196px;
}

.request__input_additional {
    width: 100%;
    height: 152px;
    padding: 12.46px 9.4px 12.46px 18.81px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #757575;
    vertical-align: top;
    resize: none;
}

@media screen and (max-width: 1710px) {
    .request__form {
        padding: 60px 40px 60px 140px;
    }
}

@media screen and (max-width: 1575px) {
    .request__form {
        padding: 40px 40px 40px 100px;
    }

    .request__subtitle {
        margin: 0;
    }

    .request__input {
        height: 31px;
        font-size: 18px;
        line-height: 21.78px;
        padding: 7px 10px;
    }

    .request__form_row {
        height: 60px;
    }

    .p-component {
        height: 31px;
        font-size: 18px;
        line-height: 21.78px;
    }

    .p-inputtext {
        padding-left: 10px;
    }

    .request__additional_container {
        height: 142px;
    }

    .request__input_additional {
        height: 102px;
    }
}

@media screen and (max-width: 1280px) {
    .request {
        height: 100%;
        max-height: unset;
        min-height: unset;
    }

    .request__container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .request__map {
        max-height: unset;
        width: 100%;
    }

    .request__map_image {
        width: 100%;
    }

    .request__title {
        text-align: center;
    }

    .request__form {
        width: 100%;
        padding: 46px 40px 33px 40px;
        height: 906px;
    }

    .request__subtitle {
        text-align: center;
        line-height: 28.4px;
        padding-top: 4px;
        padding-bottom: 20px;
    }

    .request__subtitle_span {
        display: inline;
    }

    .request__parking_containeer,
    .request__people_container {
        margin: 0;
        width: 30%;
    }
}

@media screen and (max-width: 860px) {
    .request__form {
        width: 100%;
        padding: 50px 20px 33px 20px;
    }

    .request__name_container {
        gap: 19px;
    }

    .request__form_row {
        height: 58px;
    }

    .request__dates_container {
        gap: 14px;
        margin-top: 9px;
    }

    .p-component {
        max-width: 200px;
        min-width: 70px;
        width: 100%;
    }

    .p-inputtext {
        padding-left: 6px;
    }

    .request__office_container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 18px;
    }

    .request__input_office {
        width: 50%;
    }

    .request__parking_containeer,
    .request__people_container {
        width: 50%;
    }

    .request__parking_span {
        display: none;
    }

    .request__contact_container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        width: 100%;
        gap: 22px;
    }

    .request__email_containeer {
        width: 100%;
    }

    .request__phone_containeer {
        width: 100%;
    }

    .request__additional_container {
        height: 120px;
        margin-bottom: 22px;
    }

    .request__input_additional {
        height: 93px;
    }

    .request__submit_container {
        margin: 0 auto;
    }
}

@media screen and (max-width: 580px) {
    .request__label_dates {
        display: none;
    }

    .request__label_dates-mobile {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        color: #000;
        margin-bottom: -16px;
        display: block;
    }
}

@media screen and (max-width: 490px) {
    .request__input_office {
        width: 100%;
    }
}