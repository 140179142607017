.icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 6px;
}

.icon__additional {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
} 

.icon__container {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.icon__title {
    text-align: center;
    margin-top: 25px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: default; 
}

.icon__additional__title {
    text-align: center;
    margin-top: 43px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: default; 
    max-width: 280px;
}

.icon__picture {
    overflow: visible;
    height: 162px;
    width: 162px;
}

.icon__picture_amenities {
    overflow: visible;
    height: 182px;
    width: 182px;
}

.icon__picture-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.icon__amenities {
    width: 380px;
}

.icon__amenities__title {
    text-align: center;
    margin-top: 38px;
    margin-left: auto;
    margin-right: auto;
    width: 380px;
    line-height: 25px;
}

.icon__amenities_title-first {
    margin-top: 24px;
    line-height: 25px;
}

.icon__picture_amenities-first {
    height: 206px;
    width: 206px;
    margin-top: -10px;
}

@media screen and (max-width: 480px) {
    .icon:nth-child(11) {
        -webkit-box-ordinal-group: -11;
            -ms-flex-order: -12;
                order: -12;
    }

    .icon:nth-child(4) {
        -webkit-box-ordinal-group: -10;
            -ms-flex-order: -11;
                order: -11;
    }
    
    .icon:nth-child(2) {
        -webkit-box-ordinal-group: -9;
            -ms-flex-order: -10;
                order: -10;
    }

    .icon:nth-child(3) {
        -webkit-box-ordinal-group: -8;
            -ms-flex-order: -9;
                order: -9;
    }

    .icon:nth-child(7) {
        -webkit-box-ordinal-group: -7;
            -ms-flex-order: -8;
                order: -8;
    }

    .icon:nth-child(8) {
        -webkit-box-ordinal-group: -6;
            -ms-flex-order: -7;
                order: -7;
    }

    .icon:nth-child(12) {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
    }

    .icon:nth-child(9) {
        -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
                order: 10;
    }

    .icon:nth-child(6) {
        -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
                order: 9;
    }

    .icon:nth-child(10) {
        -webkit-box-ordinal-group: 9;
            -ms-flex-order: 8;
                order: 8;
    }
}

@media screen and (max-width: 1175px) {
    .icon__additional__title {
        margin-top: 11px;
    }
}

@media screen and (max-width: 850px) {
    .icon__title {
        margin-top: 11px;
    }
}

@media screen and (max-width: 860px) {
    .icon__title {
        margin-top: 11px;
    }

    .icon__additional__title {
        width: 250px;
    }

    .icon__picture {
        height: 105px;
        width: 105px;
    }

    .icon__picture_amenities {
        height: 105px;
        width: 105px;
    }

    .icon__amenities {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        width: inherit;
        max-width: 320px;
    }

    .icon__amenities__title {
        width: 260px;
        margin-top: 16px;
        line-height: 24px;
    }

    .icon__amenities_title-first {
        width: 180px;
    }

    .icon__picture_amenities-first {
        height: 116px;
        width: 116px;
        margin-top: 0px;
    }
}